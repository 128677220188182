import React, { useRef, useEffect, useState } from 'react';
import { includes } from 'ramda';
import firebase from 'firebase/app';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import { navigate } from 'gatsby-link';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';

import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import AuthModal from '../components/AuthModal';
import Layout from '../components/layout';
import SEO from '../components/seo';
import appleWalletLoyaltyCardImg from '../images/apple-wallet-loyalty-card.png';
import businessAccountImg from '../images/business-account.png';
import greenEnergyImg from '../images/green-energy.png';
import holdingShoppingBag from '../images/holding-shopping-bag.png';
import laptopManImg from '../images/laptop-man.png';
import paletteImg from '../images/palette.png';
import qrCodeScreenImg from '../images/qr-code-screen.png';
import scanningQrCodeImg from '../images/scanning-qr-code.png';
import { getFirebase } from '../utils/signin';

configureAnchors({ offset: -70, scrollDuration: 300 });

const getPriceFromPlan = (plan, isMonthly) => {
    switch (plan) {
        case 'low':
            return isMonthly
                ? {
                      priceId: 'price_1JP9m4IEseSdJQsjNoB3z82D',
                      price: '14.99€'
                  }
                : {
                      priceId: 'price_1JP8zuIEseSdJQsjnYi1JROj',
                      price: '149.99€'
                  };
        case 'basic':
            return isMonthly
                ? {
                      priceId: 'price_1JP8zsIEseSdJQsjTXDWs8NZ',
                      price: '32.99€'
                  }
                : {
                      priceId: 'price_1JP8zsIEseSdJQsjBmREiD6X',
                      price: '329.99€'
                  };
        default:
            return isMonthly
                ? {
                      priceId: 'price_1JP8zlIEseSdJQsj8IkGHy2B',
                      price: '49.99€'
                  }
                : {
                      priceId: 'price_1JP8zlIEseSdJQsjoCIGTpo0',
                      price: '499.99€'
                  };
    }
};

function IndexPage({ location }) {
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [plan, setPlan] = useState(null);
    const [userHasAPlan, setUserHasAPlan] = useState(false);
    const [isOpenAuthModal, setIsOpenAuthModal] = useState(false);
    const [isMonthlyPricing, setIsMonthlyPricing] = useState(true);
    const [isLoadingMutation, setIsLoadingMutation] = useState(false);
    const [isShrunk, setShrunk] = useState(false);
    const extraTabs = ['digitalOrders', 'digitalLoyaltyCard'];
    const [extraTabSelected, setExtraTabSelected] = useState(extraTabs[0]);
    const scrolledRef = useRef(false);
    const hash = location.hash;
    const hashRef = useRef(hash);
    const hashFormatted = hash && hash.replace('#', '');
    useEffect(() => {
        const unregisterAuthObserver = getFirebase()
            .auth()
            .onAuthStateChanged((user) => {
                setIsSignedIn(!!user);
                setCurrentUserId(user ? user.uid : null);
                if (!user) return;

                const db = getFirebase().firestore();
                db.collection('customers')
                    .doc(user.uid)
                    .collection('subscriptions')
                    .where('status', 'in', ['trialing', 'active'])
                    .onSnapshot(async (snapshot) => {
                        console.log('user', user);
                        if (!snapshot.empty) {
                            setUserHasAPlan(true);
                            return;
                        }
                    });
            });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);
    useEffect(() => {
        if (!hash) return;

        if (hashRef.current !== hash) {
            hashRef.current = hash;
            scrolledRef.current = false;
        }
        if (scrolledRef.current) return;

        const id = hash.replace('#', '');
        if (includes(id, extraTabs)) {
            setExtraTabSelected(id);
        }
        const element = document.getElementById(`${id}-tab`);
        if (!element) return;

        setTimeout(() => {
            element.scrollIntoView({ behavior: 'smooth' });
            scrolledRef.current = true;
        }, 200);
    }, [hash]);
    useEffect(() => {
        const onScroll = () => {
            setShrunk((isShrunk) => {
                if (!isShrunk && (document.body.scrollTop > 45 || document.documentElement.scrollTop > 45)) {
                    return true;
                }

                if (isShrunk && document.body.scrollTop < 4 && document.documentElement.scrollTop < 4) {
                    return false;
                }

                return isShrunk;
            });
        };

        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, []);
    const signInSuccessHandlerSubscribe = async (authResult, redirectUrl) => {
        setCurrentUserId(authResult.user.uid);
        navigate(`/app`);
    };
    const handleOpenAuthModal = () => {
        setIsOpenAuthModal(true);
    };
    const handleRedirectCheckout = async (plan, currentUserId) => {
        setIsLoadingMutation(true);
        const db = getFirebase().firestore();
        const price = getPriceFromPlan(plan, isMonthlyPricing).priceId;
        const docRef = await db
            .collection('customers')
            .doc(currentUserId)
            .collection('checkout_sessions')
            .add({
                price,
                allow_promotion_codes: true,
                success_url: `${window.location.origin}/my-products`,
                cancel_url: window.location.origin
            });
        // Wait for the CheckoutSession to get attached by the extension
        docRef.onSnapshot((snap) => {
            const { error, url } = snap.data();
            if (error) {
                // Show an error to your customer and
                // inspect your Cloud Function logs in the Firebase console.
                alert(`An error occured: ${error.message}`);
                setIsLoadingMutation(false);
            }
            if (url) {
                // We have a Stripe Checkout URL, let's redirect.
                window.location.assign(url);
                setIsLoadingMutation(false);
            }
        });
    };
    const handleOpenDashboard = async () => {
        if (!isSignedIn) {
            handleOpenAuthModal();
            return;
        }

        navigate(`/app`);
    };
    const handleOpenClientPortal = async () => {
        try {
            setIsLoadingMutation(true);
            const functionRef = firebase
                .app()
                .functions('europe-west1')
                .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
            const { data } = await functionRef({ returnUrl: window.location.origin });
            window.location.assign(data.url);
            setIsLoadingMutation(false);
        } catch (e) {}
    };
    const perMonthText = isMonthlyPricing ? (
        <span className="text-base"> /mois</span>
    ) : (
        <span className="text-base"> /an</span>
    );
    const ctaTrial = (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
                <div className="overflow-hidden rounded-lg bg-sapin-200 shadow-xl lg:grid lg:grid-cols-2 lg:gap-4">
                    <div className="px-6 pt-10 pb-12 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
                        <div className="lg:self-center">
                            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                                <span className="block text-sapin-900">Prêt à vous lancer ?</span>
                                <span className="block text-sapin-900">
                                    Commencer votre Essai Gratuit aujourd'hui.
                                </span>
                            </h2>
                            <p className="mt-4 text-lg leading-6 text-sapin-700">
                                Essayez d'abord & décidez plus tard, aucune carte de crédit n'est requise.
                                Ceci est un essai gratuit de 14 jours.
                            </p>
                            <button
                                onClick={handleOpenDashboard}
                                className="mt-8 inline-flex items-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-sapin-900 shadow hover:bg-sapin-50"
                            >
                                Commencer l'essai
                            </button>
                        </div>
                    </div>
                    <div className="aspect-w-5 aspect-h-3 -mt-6 md:aspect-w-2 md:aspect-h-1">
                        <img
                            className="translate-x-6 translate-y-6 transform rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
                            src={qrCodeScreenImg}
                            alt="qr code example"
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <Layout>
            <SEO
                title="eMenila - QR code menu, Commandes en ligne, et Cartes de fidélité"
                keywords={[
                    `Menus`,
                    `QR code`,
                    `Restaurant`,
                    `digital`,
                    `Commande en ligne`,
                    `carte de fidélité`
                ]}
            />
            {isLoadingMutation && (
                <div className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50 flex items-center justify-center">
                    <svg
                        className="animate-spin ml-1 mr-3 h-28 w-28 text-sapin-400"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                        ></circle>
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                </div>
            )}
            <AuthModal
                signInSuccessHandler={signInSuccessHandlerSubscribe}
                isOpen={isOpenAuthModal}
                setIsOpen={setIsOpenAuthModal}
            />
            <div className="pt-24">
                <div className="container px-3 mx-auto flex flex-wrap flex-col-reverse md:flex-row items-center">
                    <div className="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
                        <p className="tracking-loose w-full">eMenila</p>
                        <h1 className="mb-4 mt-1 text-3xl font-bold leading-tight">
                            Un menu digital pour votre restaurant
                        </h1>
                        <p className="leading-normal text-xl mb-8">
                            Fini les menus imprimés qui tournent de main en main. Offrez à vos clients une
                            solution digitale pour découvrir vos menus.
                        </p>

                        <button
                            onClick={handleOpenDashboard}
                            className="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg mb-20"
                        >
                            Essayer gratuitement
                        </button>
                    </div>

                    <div className="w-full md:w-3/5 py-6 text-center flex items-center justify-center">
                        <img className="w-3/5" src={scanningQrCodeImg} />
                    </div>
                </div>
            </div>
            <div class="relative -mt-12 lg:-mt-24">
                <svg viewBox="0 0 1428 174" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g transform="translate(-2.000000, 44.000000)" fill="#FFFFFF" fill-rule="nonzero">
                            <path
                                d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                                opacity="0.100000001"
                            ></path>
                            <path
                                d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                                opacity="0.100000001"
                            ></path>
                            <path
                                d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                                id="Path-4"
                                opacity="0.200000003"
                            ></path>
                        </g>
                        <g transform="translate(-4.000000, 76.000000)" fill="#FFFFFF" fill-rule="nonzero">
                            <path d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"></path>
                        </g>
                    </g>
                </svg>
            </div>
            <section className="bg-white py-8">
                <div className="container max-w-5xl mx-auto m-8">
                    <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
                        eMenila
                    </h2>
                    <div className="w-full mb-4">
                        <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                    </div>

                    <div className="flex flex-col-reverse sm:flex-row flex-wrap justify-center items-center">
                        <div className="w-5/6 sm:w-1/2 sm:p-6">
                            <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                                Modifier vos menus en quelques secondes
                            </h3>
                            <p className="text-gray-600 mb-8">
                                Dans votre "Espace Pro" eMenila vous pouvez modifier vos menus à tout moment.
                                Lorsque vous êtes dans l'incapacité de fournir un élément de votre menu (plat,
                                boisson, dessert, ...), il vous sera possible en temps réel de pouvoir le
                                masquer de votre menu digital, sans avoir à modifier votre QR code ou à
                                réimprimer votre carte. De même, lorsque vous souhaiterez ajouter des éléments
                                à votre menus (plat du jour, dessert du jour, ...), vous pourrez le faire en
                                direct.
                            </p>
                        </div>
                        <div className="w-full sm:w-1/2 p-6 flex items-center justify-center">
                            <img className="w-3/5" src={laptopManImg} />
                        </div>
                    </div>
                    <div className="flex flex-wrap justify-center items-center">
                        <div className="w-full sm:w-1/2 p-6 flex items-center justify-center">
                            <img className="w-3/5" src={greenEnergyImg} />
                        </div>
                        <div className="w-5/6 sm:w-1/2 sm:p-6">
                            <div className="align-middle text-center sm:text-left">
                                <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                                    Faire des économies et participer à l'effort écologique
                                </h3>
                                <p className="text-gray-600 mb-8">
                                    Les menus imprimés ont un impact énorme sur l'environnement, mais avec les
                                    menus digitaux, vous pouvez réduire considérablement votre utilisation de
                                    papier. Vous n'aurez plus besoin d'imprimer vos menus, peu importe combien
                                    de fois vous les changez, le QR code restera le même. Cela permettra non
                                    seulement de réduire vos coûts d'impression, mais aussi de faire un geste
                                    pour l'environnement en utilisant moins de papier.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col-reverse sm:flex-row flex-wrap justify-center items-center">
                            <div className="w-5/6 sm:w-1/2 sm:p-6 text-center sm:text-left">
                                <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                                    Gagner en productivité, et en hygiène
                                </h3>
                                <p className="text-gray-600 mb-8">
                                    Ne perdez plus de temps à apporter vos cartes ou vos ardoises de table en
                                    table, de main en main. Cette disponibilité gagnée, vous permettra de
                                    satisfaire vos clients avec un service plus rapide, grâce au QR code. Vos
                                    clients sauront apprécier le respect des règles d'hygiène, et l'autonomie
                                    pour commander sans pression.
                                </p>
                            </div>
                            <div className="w-full sm:w-1/2 p-6 flex items-center justify-center">
                                <img className="w-4/5" src={businessAccountImg} />
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-center items-center">
                            <div className="w-full sm:w-1/2 p-6 flex items-center justify-center">
                                <img className="w-2/5" src={paletteImg} />
                            </div>
                            <div className="w-5/6 sm:w-1/2 sm:p-6">
                                <div className="align-middle text-center sm:text-left">
                                    <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                                        Donner une image professionnelle à votre établissement
                                    </h3>
                                    <p className="text-gray-600 mb-8">
                                        Nous allons créer pour vous une page dédiée à votre établissement, sur
                                        laquelle vous pourrez présenter vos menus, mais pas seulement. Depuis
                                        votre "Espace Pro" eMenila, vous pourrez définir le nom de votre
                                        établissement, son numéro de téléphone, son logo et même ajouter une
                                        photo de couverture. En outre, vous pourrez également personnaliser le
                                        QR code en utilisant vos propres couleurs, pour qu'il corresponde à
                                        l'image de votre établissement.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*<Parallax*/}
            {/*    bgImage={qrCodeScreenImg}*/}
            {/*    renderLayer={percentage => (*/}
            {/*        <div*/}
            {/*            style={{*/}
            {/*                position: 'absolute',*/}
            {/*                background: `rgba(255, 125, 0, ${percentage * 1})`,*/}
            {/*                left: '50%',*/}
            {/*                top: '50%',*/}
            {/*                width: percentage * 500,*/}
            {/*                height: percentage * 500,*/}
            {/*            }}*/}
            {/*        />*/}
            {/*    )}*/}
            {/*>*/}
            {/*    <p>... Content</p>*/}
            {/*</Parallax>*/}
            {ctaTrial}

            {/* Waves SVG */}
            <div className="">
                <svg
                    className="wave-top"
                    viewBox="0 0 1439 147"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g transform="translate(-1.000000, -14.000000)" fill-rule="nonzero">
                            <g className="wave" fill="#f8fafc">
                                <path d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z"></path>
                            </g>
                            <g transform="translate(1.000000, 15.000000)" fill="#FFFFFF">
                                <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
                                    <path
                                        d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                                        opacity="0.100000001"
                                    ></path>
                                    <path
                                        d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                                        opacity="0.100000001"
                                    ></path>
                                    <path
                                        d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                                        opacity="0.200000003"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>

            {/* CTA block */}
            <section className="container mx-auto text-center py-6 mb-12">
                <h1 className="w-full my-2 text-5xl font-bold leading-tight text-center text-white">
                    Un menu QR code, mais pas que !
                </h1>
                <div className="w-full mb-4">
                    <div className="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
                </div>
                <div className="container bg-gray-50 rounded mt-6 p-6 pb-20 flex items-center justify-center flex-col">
                    <p className="w-full mt-6 mb-6 text-md leading-tight text-center text-black">
                        Découvrez nos fonctionnalités en option
                    </p>
                    <ul className="flex justify-center items-center text-sm font-medium text-center text-gray-500 dark:border-gray-700 dark:text-gray-400">
                        <li className="">
                            <a
                                href={`#${extraTabs[0]}`}
                                aria-current="page"
                                className={
                                    extraTabSelected === extraTabs[0]
                                        ? 'shadow inline-block p-4 text-white text-xl gradient rounded-l-lg active dark:bg-gray-800 dark:text-blue-500'
                                        : 'shadow inline-block text-xl p-4 rounded-l-lg text-white text-sapin-500 hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300'
                                }
                            >
                                Commande digitale
                            </a>
                        </li>
                        <li className="">
                            <a
                                href={`#${extraTabs[1]}`}
                                aria-current="page"
                                className={
                                    extraTabSelected === extraTabs[1]
                                        ? 'shadow inline-block p-4 text-white text-xl gradient rounded-r-lg active dark:bg-gray-800 dark:text-blue-500'
                                        : 'shadow inline-block text-xl p-4 rounded-r-lg text-white text-sapin-500 hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300'
                                }
                            >
                                Carte de fidélité digitale
                            </a>
                        </li>
                    </ul>
                    {extraTabSelected === extraTabs[0] ? (
                        <ScrollableAnchor id={extraTabs[0]}>
                            <div className="flex flex-col sm:flex-row mt-6">
                                <div className="flex-1 flex items-center justify-center relative">
                                    <img
                                        className="relative h-80 sm:absolute sm:h-50"
                                        src={holdingShoppingBag}
                                    />
                                </div>
                                <div className="flex-1 flex items-start justify-start flex-col">
                                    <h2 className="text-md text-black text-left">
                                        Votre établissement sert principalement des plats:
                                    </h2>
                                    <div className="text-xl text-sapin-800 mt-4">🥡 À emporter</div>
                                    <p className="text-sm text-gray-800 text-left">
                                        Grâce au QR code, vos clients pourront commander en toute facilité et
                                        sans attendre. Cela évitera les files d'attente devant l'établissement
                                        et l'exaspération des clients. Leurs commandes seront directement
                                        envoyées dans votre espace de commande où vous pourrez les identifier
                                        grâce à un numéro à 4 chiffres (donné au client après avoir validé la
                                        commande) et leur numéro de téléphone. De plus, vos clients pourront
                                        profiter de leur temps libre (plage, shopping, ...) car ils pourront
                                        suivre l'avancée de leur commande directement depuis leur téléphone.
                                    </p>
                                    <div className="text-xl text-sapin-800 mt-4">🍽 Sur place</div>
                                    <p className="text-sm text-gray-800 text-left">
                                        Notre plateforme crée des QR codes uniques pour chaque table. Vos
                                        clients pourront commander en toute facilité et sans avoir à
                                        interrompre l'un de vos serveurs occupés. Leurs commandes seront
                                        directement envoyées dans votre espace de commande où vous pourrez
                                        identifier de quelle table elles proviennent.
                                    </p>
                                </div>
                            </div>
                        </ScrollableAnchor>
                    ) : (
                        <ScrollableAnchor id={extraTabs[1]}>
                            <div className="flex sm:flex-col md:flex-row mt-6">
                                <div
                                    className="flex-1 flex items-center justify-center relative"
                                    style={{ height: 300 }}
                                >
                                    <img
                                        style={{ height: 500, top: -32 }}
                                        className="absolute"
                                        src={appleWalletLoyaltyCardImg}
                                    />
                                </div>
                                <div className="flex-1 flex items-start justify-start flex-col">
                                    <h2 className="text-xl text-black text-left">
                                        Tout ce dont vous avez besoin pour fidéliser vos clients grâce aux
                                        cartes de fidélité digitales.
                                    </h2>
                                    <p className="text-sm text-gray-800 text-left mt-4">
                                        Les clients ont déjà suffisamment de cartes dans leur portefeuille et
                                        oublient ou perdent souvent leurs cartes de fidélité papier.
                                        <br />
                                        <br />
                                        Offrez à vos clients une carte de fidélité digitale qu'ils pourront
                                        ranger dans leur Apple Wallet ou Android Wallet. Sur cette carte, aux
                                        couleurs de votre enseigne, sera renseigné un QR Code unique à scanner
                                        à l'aide de l'appareil photo de leur smartphone. Ce scan agira comme
                                        un tampon sur une carte imprimée.
                                    </p>
                                </div>
                            </div>
                        </ScrollableAnchor>
                    )}
                </div>
                <button
                    onClick={handleOpenDashboard}
                    className="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg"
                >
                    Essayer gratuitement !
                </button>
            </section>
            <div className="relative -mt-12 lg:-mt-24">
                <svg viewBox="0 0 1428 174" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g transform="translate(-2.000000, 44.000000)" fill="#FFFFFF" fill-rule="nonzero">
                            <path
                                d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                                opacity="0.100000001"
                            ></path>
                            <path
                                d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                                opacity="0.100000001"
                            ></path>
                            <path
                                d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                                id="Path-4"
                                opacity="0.200000003"
                            ></path>
                        </g>
                        <g transform="translate(-4.000000, 76.000000)" fill="#FFFFFF" fill-rule="nonzero">
                            <path d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"></path>
                        </g>
                    </g>
                </svg>
            </div>

            <section className="bg-white pt-8">
                <div className="container mx-auto flex flex-wrap pt-4 pb-12">
                    <h3 className="w-full my-2 text-3xl font-bold leading-tight text-center text-gray-800">
                        Les questions les plus courantes
                    </h3>
                    <div className="w-full mb-8">
                        <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                    </div>

                    <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
                        <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden">
                            <div className="w-full text-xl font-semibold text-gray-900 px-6 mb-2">
                                Qu'est-ce qu'un QR code ?
                            </div>
                            <p className="text-gray-500 px-6 mb-5 text-sm font-light text-justify">
                                Le QR code est un type de code-barres que l'on peut lire à l'aide d'un
                                smartphone. Grâce à l'appareil photo de celui-ci (iPhone ou android), il est
                                possible de scanner le code et d'ouvrir le lien associé, permettant d'accéder
                                directement à une page web dédiée à votre établissement, présentant votre
                                carte.
                            </p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
                        <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden">
                            <div className="w-full text-xl font-semibold text-gray-900 px-6 mb-2">
                                Comment puis-je changer mon menu ?
                            </div>
                            <p className="text-gray-500 px-6 mb-5 text-sm font-light text-justify">
                                Vous pouvez en temps réel ajouter, modifier ou même masquer temporairement vos
                                menus et articles de menus en un seul clic depuis votre espace pro eMenila, et
                                tout cela sans avoir à modifier votre QR code ou à réimprimer votre carte !
                            </p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
                        <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden">
                            <div className="w-full text-xl font-semibold text-gray-900 px-6 mb-2">
                                Comment mes clients peuvent obtenir la carte de fidélité digitale ?
                            </div>
                            <p className="text-gray-500 px-6 mb-5 text-sm font-light text-justify">
                                Après avoir souscrit à notre option "Carte de fidélité digitale", vos clients
                                peuvent obtenir votre carte de fidélité digitale directement depuis votre page
                                publique (accessible depuis votre QR code). Sur cette page, on y retrouve vos
                                menus mais aussi une section en bas de page qui leur permet d'obtenir une
                                carte de fidélité, qui s'enregistrera soit dans leur application Apple
                                "Cartes" ou dans Android Wallet.
                            </p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink max-w-md">
                        <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden">
                            <div className="w-full text-xl font-semibold text-gray-900 px-6 mb-2">
                                Est-ce que mes clients pourront commander directement depuis le menu digitale
                                ?
                            </div>
                            <p className="text-gray-500 px-6 mb-5 text-sm font-light text-justify">
                                Après avoir souscrit à notre option "Commandes digitales", vos clients peuvent
                                commander directement depuis votre page publique (accessible depuis votre QR
                                code) où se trouvent vos menus. En passant leur commande, ils pourront choisir
                                la quantité souhaitée (x2, x3, ...), mais aussi laisser des consignes
                                spécifiques (ex: "Sans tomates svp"). Vous recevrez en temps réel la commande
                                dans votre espace pro eMenila dans un tableau organisé. Vous pouvez dès
                                maintenant consulter gratuitement cet onglet "Commandes du jour" dans votre
                                espace pro eMenila
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {ctaTrial}
            <section className="bg-white pt-4">
                <div className="container mx-auto flex flex-wrap pt-4 pb-12 max-w-3xl">
                    <h5 className="w-full mt-2 text-sm font-bold leading-tight text-center text-sapin-600">
                        BLOG
                    </h5>
                    <h3 className="w-full mb-2 text-3xl font-bold leading-tight text-center text-gray-800">
                        Boostez votre restaurant grâce à la digitalisation : découvrez nos astuces !
                    </h3>
                    <div className="w-full mb-8">
                        <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                    </div>
                    <div className="text-gray-600 px-6 mb-5 text-md text-center">
                        Vous voulez faire prospérer votre restaurant tout en offrant une expérience unique à
                        votre clientèle ? La digitalisation est la solution ! Chez eMenila, nous vous
                        proposons de découvrir notre article phare : "
                        <Link
                            to="/la-digitalisation-au-service-de-votre-restaurant-menus-marketing-et-astuces-pour-reussir"
                            className="text-sapin-500 hover:text-xl underline font-semibold"
                        >
                            La digitalisation au service de votre restaurant : menus, marketing et astuces
                            pour réussir
                        </Link>
                        ". Vous y trouverez toutes les clés pour réussir votre digitalisation : des menus
                        interactifs, une commande en ligne facilitée et un marketing optimisé.
                    </div>
                </div>
            </section>

            {/*<section className="bg-white pt-8 ">*/}
            {/*    <div className="relative isolate overflow-hidden bg-gray-900 py-16 sm:py-24 lg:py-32">*/}
            {/*        <div className="mx-auto max-w-7xl px-6 lg:px-8">*/}
            {/*            <div*/}
            {/*                className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 lg:max-w-none lg:grid-cols-2">*/}
            {/*                <div className="max-w-xl lg:max-w-lg">*/}
            {/*                    <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Subscribe to*/}
            {/*                        our newsletter.</h2>*/}
            {/*                    <p className="mt-4 text-lg leading-8 text-gray-300">Nostrud amet eu ullamco nisi aute in*/}
            {/*                        ad minim nostrud adipisicing velit quis. Duis tempor incididunt dolore.</p>*/}
            {/*                    <div className="mt-6 flex max-w-md gap-x-4">*/}
            {/*                        <label htmlFor="email-address" className="sr-only">Email address</label>*/}
            {/*                        <input id="email-address" name="email" type="email" autoComplete="email" required*/}
            {/*                               className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"*/}
            {/*                               placeholder="Enter your email"/>*/}
            {/*                            <button type="submit"*/}
            {/*                                    className="flex-none rounded-md bg-indigo-500 py-2.5 px-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Subscribe*/}
            {/*                            </button>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">*/}
            {/*                    <div className="flex flex-col items-start">*/}
            {/*                        <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">*/}
            {/*                            <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24"*/}
            {/*                                 stroke-width="1.5" stroke="currentColor" aria-hidden="true">*/}
            {/*                                <path stroke-linecap="round" stroke-linejoin="round"*/}
            {/*                                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"/>*/}
            {/*                            </svg>*/}
            {/*                        </div>*/}
            {/*                        <dt className="mt-4 font-semibold text-white">Weekly articles</dt>*/}
            {/*                        <dd className="mt-2 leading-7 text-gray-400">Non laboris consequat cupidatat laborum*/}
            {/*                            magna. Eiusmod non irure cupidatat duis commodo amet.*/}
            {/*                        </dd>*/}
            {/*                    </div>*/}
            {/*                    <div className="flex flex-col items-start">*/}
            {/*                        <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">*/}
            {/*                            <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24"*/}
            {/*                                 stroke-width="1.5" stroke="currentColor" aria-hidden="true">*/}
            {/*                                <path stroke-linecap="round" stroke-linejoin="round"*/}
            {/*                                      d="M10.05 4.575a1.575 1.575 0 10-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 013.15 0v1.5m-3.15 0l.075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 013.15 0V15M6.9 7.575a1.575 1.575 0 10-3.15 0v8.175a6.75 6.75 0 006.75 6.75h2.018a5.25 5.25 0 003.712-1.538l1.732-1.732a5.25 5.25 0 001.538-3.712l.003-2.024a.668.668 0 01.198-.471 1.575 1.575 0 10-2.228-2.228 3.818 3.818 0 00-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0116.35 15m.002 0h-.002"/>*/}
            {/*                            </svg>*/}
            {/*                        </div>*/}
            {/*                        <dt className="mt-4 font-semibold text-white">No spam</dt>*/}
            {/*                        <dd className="mt-2 leading-7 text-gray-400">Officia excepteur ullamco ut sint duis*/}
            {/*                            proident non adipisicing. Voluptate incididunt anim.*/}
            {/*                        </dd>*/}
            {/*                    </div>*/}
            {/*                </dl>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <svg className="absolute top-0 left-1/2 -z-10 h-[42.375rem] -translate-x-1/2 blur-3xl xl:-top-6"*/}
            {/*             viewBox="0 0 1155 678" fill="none">*/}
            {/*            <path fill="url(#09dbde42-e95c-4b47-a4d6-0c523c2fca9a)" fill-opacity=".3"*/}
            {/*                  d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"/>*/}
            {/*            <defs>*/}
            {/*                <linearGradient id="09dbde42-e95c-4b47-a4d6-0c523c2fca9a" x1="1155.49" x2="-78.208"*/}
            {/*                                y1=".177" y2="474.645" gradientUnits="userSpaceOnUse">*/}
            {/*                    <stop stop-color="#9089FC"/>*/}
            {/*                    <stop offset="1" stop-color="#FF80B5"/>*/}
            {/*                </linearGradient>*/}
            {/*            </defs>*/}
            {/*        </svg>*/}
            {/*    </div>*/}
            {/*</section>*/}
        </Layout>
    );
}

export default IndexPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
